
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { HomePageProps } from './HomePage.types';
import { PageWrapper } from "@otomoro/components/layout/PageWrapper";
import { HeroMedia } from "@otomoro/components/shared/HeroMedia";
import { HomeFlexibleContent } from "@otomoro/components/home/HomeFlexibleContent";
export default function HomePage({ data }: HomePageProps) {
    if (!data) {
        return (<div className="text-center">Please start editing your Home document to see the preview!</div>);
    }
    const blocks = data.flexibleContent as any;
    const lastType = blocks[blocks.length - 1]._type as string;
    return (<PageWrapper noPb={lastType === 'alternateSection'}>
			<HeroMedia media={data.heroImage} headline="different by nature"/>
			<HomeFlexibleContent blocks={data.flexibleContent as any}/>
		</PageWrapper>);
}

    async function __Next_Translate__getStaticProps__19498208888__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/HomePage',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19498208888__ as getStaticProps }
  